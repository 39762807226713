import React from "react";
import DefaultHero from "../components/DefaultHero";
import Footer from "../components/Footer";
import Header from "../components/Header";
import QueryString from "../helper/QueryString";
import StringBuilder from "../helper/StringBuilder";
import { graphql } from "gatsby";
import {
    Videoueberwachung,
    Brand,
    Tuerschloss,
    Alarmanlage,
} from "../components/PriceCalculator/elements/Constants";

export default ({ data, pageContext }) => {
    const { city, product } = pageContext;
    let image;
    switch (product) {
        case Alarmanlage:
            image =
                "/img/hero/default/alarmanlagen-von-deutschlands-nr-1-pr.jpg";

            break;

        case Videoueberwachung:
            image =
                "/img/hero/default/videoanlagen-von-deutschlands-nr-1-pr.jpg";
            break;

        case Brand:
            image =
                "/img/hero/" +
                (city == null
                    ? data.site.siteMetadata.image
                    : city.slug + "/" + city.image);
            break;

        case Tuerschloss:
            image =
                "/img/hero/default/schliessanlagen-von-deutschlands-nr-1-app.jpg";
            break;

        default:
            image =
                "/img/hero/default/alarmanlagen-von-deutschlands-nr-1-pr.jpg";
            break;
    }
    const contextData = {
        title: "Preisrechner",
        image: image,
        image768: "/img/hero/empty.gif",
        image1024: image,
        image1216: image,
        image1408: image,
        showPriceCalculator: true,
        defaultCssClass: "price-hero",
        product: product,
        showBottom: true,
        city: city,
        noIndex: true,
    };
    if (typeof city !== "undefined") {
        let query = new QueryString();
        query.setValue("region", city.key);
    }
    let stringBuilder = new StringBuilder(city, product);
    let page;
    if (product) {
        page = {
            title: data.site.siteMetadata.priceMetadata.title.replace(
                "Sicherheitssystem",
                stringBuilder.getMetaDataProductSingularName()
            ),
            ogTitle: data.site.siteMetadata.priceMetadata.ogTitle.replace(
                "Sicherheitssystem",
                stringBuilder.getMetaDataProductSingularName()
            ),
            description:
                data.site.siteMetadata.priceMetadata.description.replace(
                    "Sicherheitssystem",
                    stringBuilder.getMetaDataProductSingularName()
                ),
            ogDescription:
                data.site.siteMetadata.priceMetadata.ogDescription.replace(
                    "Sicherheitssystem",
                    stringBuilder.getMetaDataProductSingularName()
                ),
            keywords:
                stringBuilder.getMetaDataProductName() +
                (city ? " " + city.name : ""),
        };
    }
    return (
        <div>
            <Header
                page={page || data.site.siteMetadata.priceMetadata}
                siteMetadata={data.site.siteMetadata}
                contextData={contextData}
            />
            <DefaultHero context={contextData} />
            <Footer small={true} />
        </div>
    );
};

export const query = graphql`
    query PriceCalcQuery {
        site {
            siteMetadata {
                title
                keywords
                description
                headerImage
                ogTitle
                ogDescription
                image
                image768
                image1024
                image1216
                image1408
                priceMetadata {
                    title
                    keywords
                    description
                    headerImage
                    ogTitle
                    ogDescription
                }
            }
        }
    }
`;
